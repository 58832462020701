<template>
  <div></div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {
    if (this.isPc) {
      this.$router.push("/index");
    } else {
      this.$router.push("/mobilehome");
    }
  },
};
</script>

<style lang="scss"></style>
